import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import $ from "jquery";
import "./App.css";
import admin, { getMyStatus } from "./js/admin";
import auth, { switchServer, appRoot, get, navigateToLogin, ajaxErrorEnd, getString, globalServers, post } from "./js/auth";
import Login from "./components/login/Login";
import "./css/eatwithme.css";
import Menu from "./components/menus/Menu";
import { connect } from "react-redux";
import { dispatchChangeLanguage } from "./actions/EatWithMeActions";
import { locals, admin_locals } from "./langs/languages";
import Register from "./components/login/Register";
import PasswordForgotten from "./components/login/PasswordForgotten";
import PasswordReset from "./components/login/PasswordReset";
import EatWithMeActions from "./actions/EatWithMeActions";
import TablesOverview from "./main/tablesOverview/TablesOverview";
import DrinkBar from "./main/drinkbar/DrinkBar";
import Kitchen from "./main/kitchen/Kitchen";
import ProductionLine from "./main/productionLine/ProductionLine";
import TableReservations from "./main/tableReservations/TableReservations";
import { store } from ".";
import Profile from "./admin/Profile";
import Consent from "./admin/Consent";
import WaiterScreen from "./main/kitchen/WaiterScreen";
import GuestScreen from "./main/kitchen/GuestScreen";
import Softphone from "./main/tablesOverview/Softphone";

class App extends Component {

  check = () => {
    const t = this;
    if (t.props.history.location.pathname.startsWith("/consent")) {
      t.getTableServiceStatus()
    } else {
      t.onloadIndexHtml(t);
    }
  }

  oldcheck = () => {
    var t = this;
    auth
      .checkSocialLoginStatus()
      .done(function () {
        if (t.props.history.location.pathname.startsWith("/consent")) {
          t.getTableServiceStatus()
        } else { t.onloadIndexHtml(t); }
      })
      .fail(function () {
        if (t.props.history.location.pathname.startsWith("/consent")) {
          t.getTableServiceStatus()
        } else {

          if (localStorage.server !== auth.globalserver && typeof localStorage.globalId != "undefined") {
            auth.server = auth.globalserver;
            switchServer({ globalId: localStorage.globalId, serverUrl: localStorage.server }).done(() => {
              t.props.history.push("/");
            }).fail(() => {
              if (!t.props.history.location.pathname.startsWith("/login")) {
                t.props.history.push("/login");
              }
            });
            return;
          }

          let local = locals[localStorage.language];
          let admin_local = admin_locals[localStorage.language];
          t.props.changeLanguage(local, admin_local, localStorage.language, localStorage.country);
          if (
            t.props.history.location.pathname.startsWith("/consent") ||
            t.props.history.location.pathname.startsWith("/login") ||
            t.props.history.location.pathname.startsWith("/passwordReset") ||
            t.props.history.location.pathname.startsWith("/passwordForgotten") ||
            t.props.history.location.pathname.startsWith("/register")
          )
            return;
          t.props.history.push("/login");
        }
      });

  };

  componentDidMount = () => {

    const queryParams = new URLSearchParams(this.props.history.location.search);

    const loginToken = queryParams.get("loginToken");
    const server_url = queryParams.get("server_url");
    const instance = queryParams.get("instance");
    const restaurant_id = queryParams.get("restaurant_id");

    if (loginToken) {
      auth.server = server_url;
      localStorage.server = auth.server;
      console.error("login? " + this.props.history.location.pathname);
      console.error("Login with login token: " + loginToken + " " + restaurant_id + " " + server_url);
      post("login?instance=" + instance, "username=" + encodeURIComponent("token:" + restaurant_id + "/" + loginToken) + "&password=" + encodeURIComponent(loginToken) + "&user=true", {}, true, 0, false, 30000)
        .done(data => {
          console.error("Login succeeded");
          if (data == null) {
            alert("Failed to authenticate user! Username or password is wrong!");
          } else {
            localStorage.id = data.customer.id;
            localStorage.email = data.customer.email;
            localStorage.name = data.customer.name;
            localStorage.surname = data.customer.surname;
            localStorage.sessionId = data.sessionId;
            localStorage.instance = instance;
          }


          //loginBackground(loginUrl)
          //                .done(() => {
          get("tableService/getLocalFromGlobalId/" + restaurant_id).done(id => {
            sessionStorage.restaurantSelected = id;
            console.error("getLocalFromGlobalId succeeded");
            admin.getMyStatus(data => {
              console.error("getMyStatus succeeded");
              localStorage.instance = instance;
              this.props.history.push(this.props.history.location.pathname);
            })
          });
        }).fail(() => {
          auth.ajaxError = false;
          console.error("Login failed with login token");
        });
    } else {
      this.check();
    }
  }

  componentDidUpdate = () => {
    if (!this.props.myStatus.id && !this.props.history.location.pathname.startsWith("/login") && !this.props.history.location.pathname.startsWith("/register") && !this.props.history.location.pathname.startsWith("/passwordForgotten") && !this.props.history.location.pathname.startsWith("/passwordReset")) {
      const queryParams = new URLSearchParams(this.props.history.location.search);
      const loginToken = queryParams.get("loginToken");
      if (!loginToken) {
        this.props.history.push("/login");
      } else {
        const server_url = queryParams.get("server_url");
        const instance = queryParams.get("instance");
        const restaurant_id = queryParams.get("restaurant_id");

        if (loginToken) {
          auth.server = server_url;
          localStorage.server = auth.server;
          console.error("login? " + this.props.history.location.pathname);
          console.error("Login with login token: " + loginToken + " " + restaurant_id + " " + server_url);
          post("login?instance=" + instance, "username=" + encodeURIComponent("token:" + restaurant_id + "/" + loginToken) + "&password=" + encodeURIComponent(loginToken) + "&user=true", {}, true, 0, false, 30000)
            .done(data => {
              console.error("Login succeeded");
              if (data == null) {
                alert("Failed to authenticate user! Username or password is wrong!");
              } else {
                localStorage.id = data.customer.id;
                localStorage.email = data.customer.email;
                localStorage.name = data.customer.name;
                localStorage.surname = data.customer.surname;
                localStorage.sessionId = data.sessionId;
                localStorage.instance = instance;
                sessionStorage.restaurantSelected = data.restaurant_id;
              }


              //loginBackground(loginUrl)
              //                .done(() => {
              get("tableService/getLocalFromGlobalId/" + restaurant_id).done(id => {
                sessionStorage.restaurantSelected = id;
                console.error("getLocalFromGlobalId succeeded");
                admin.getMyStatus(data => {
                  console.error("getMyStatus succeeded");
                  this.props.history.push(this.props.history.location.pathname);
                })
              });
            }).fail(() => {
              auth.ajaxError = false;
              console.error("Login failed with login token");
            });
        }
      }
    }
  }

  render() {

    window.ajaxErrorEnd = ajaxErrorEnd;
    const queryParams = new URLSearchParams(this.props.history.location.search);
    const loginToken = queryParams.get("loginToken");
    if (loginToken) {
      return null;
    }
    return (
      <React.Fragment>
        <Switch>
          <Route path="/login" render={props => <Login {...props} keep="false" onLogin={this.onloadIndexHtml} />} />
          <Route path="/register" render={props => <Register {...props} keep="false" onLogin={this.onloadIndexHtml} />} />
          <Route path="/passwordForgotten" render={props => <PasswordForgotten {...props} keep="false" />} />
          <Route path="/passwordReset" render={props => <PasswordReset {...props} keep="false" />} />
          <Route path="/tablesOverview" render={props => <TablesOverview {...props} onReload={this.onReload} />} />
          <Route path="/tableReservations" render={props => <TableReservations {...props} onReload={this.onReload} />} />
          <Route path="/drinkbar" render={props => <DrinkBar {...props} onReload={this.onReload} />} />
          <Route path="/kitchen" render={props => <Kitchen {...props} onReload={this.onReload} />} />
          <Route path="/waiterScreen" render={props => <WaiterScreen {...props} onReload={this.onReload} />} />
          <Route path="/guestScreen" render={props => <GuestScreen {...props} onReload={this.onReload} />} />
          <Route path={"/profile"} render={props => <Profile {...props} onReload={this.onReload} />} />
          <Route path={"/consent"} render={props => <Consent {...props} onReload={this.onReload} />} />
          <Route path="/productionLinesMenu/productionLine" render={props => <ProductionLine {...props} onReload={this.onReload} />} />
          <Route path="/" render={props => <Menu {...props} onReload={this.onReload} />} />
        </Switch>
        {this.props.voipphoneSelected && <Softphone />}
      </React.Fragment>
    );
  }

  getTableServiceStatus = () => {
    let query = new URLSearchParams(this.props.history.location.search);

    //getGlobal("adminService/" + query.get("rr") + "/getRestaurant").done(data => {
    sessionStorage.restaurantSelected = query.get("rr");
    auth.server = localStorage.server = query.get("u").substring(0, query.get("u").length - 1);
    auth.getMyStatus(data => {
      sessionStorage.restaurantSelected = data.restaurant_id;
      auth.setMyStatus(data);
    })
    //})
  }

  onloadIndexHtml = () => {
    const t = this;
    const def = $.Deferred();
    const { resetRestaurants, changeLanguage } = this.props;

    let local = locals[localStorage.language];
    let admin_local = admin_locals[localStorage.language];
    changeLanguage(local, admin_local, localStorage.language, localStorage.country);

    if (this.props.history.location.pathname.startsWith("/login")) {
      def.resolve();
      return;
    }
    admin.getMyStatus(
      async data => {
        console.log("App.getMyStatus OK", auth.server);
        const href = window.location.href;
        if (href.indexOf(":3000") === -1 && auth.device.platform === "browser" && auth.myStatus.restaurant_global_id) {//} && !href.startsWith(auth.server) /*&& globalServers.filter(s => href.startsWith(s)).length !== 0*/) {
          if (auth.server !== auth.globalserver) {
            var u = auth.server.replace("restaurant.eatwithme", "console.eatwithme").replace(":8448", "");
            /*
            if (!href.startsWith(u))
              getString(u).done(() => {
                window.location.href = u + "/?r=" + auth.myStatus.restaurant_id + "&s=" + auth.server;
              }).fail(() => {
                auth.ajaxError = false;
              })
              */
          } else if (!href.startsWith("https://console.eatwithme.online/#")) {
            window.location.href = "https://console.eatwithme.online/" + window.location.hash;
            return;
          }
        }

        this.props.ai.initSpeach();

        if (data.status === "none") {
          t.setState({ showLogin: true });
          return;
        }
        t.getRestaurants().done(restaurants => {
          var r = restaurants.find(r => (r.isActive === undefined || r.isActive) && r.id === Number(sessionStorage.restaurantSelected) && r.fromLocal);
          var server = auth.server + "/";
          if (!r) {
            r = restaurants.find(r => (r.isActive === undefined || r.isActive) && ((auth.server !== auth.globalserver && r.serverUrl === server && r.fromLocal) || (auth.server === auth.globalserver && r.serverUrl === "" && r.fromLocal)));
          }
          if (!r) {
            r = restaurants.find(r => ((r.isActive === undefined || r.isActive) && r.fromLocal));
          }
          if (r) {
            var serverUrl = !r.serverUrl ? auth.globalserver + "/" : r.serverUrl;

            if (auth.myStatus.is_global_server && serverUrl !== server) {
              console.log("Switch to server " + serverUrl);
              t.pickRestaurant(r.globalId, r.name, r.languages, r.serverUrl, r.backupUrl, r.instance);
              return;
            }
            sessionStorage.restaurantSelected = r.id;
            store.dispatch(EatWithMeActions.resetActiveRestaurant(sessionStorage.restaurantSelected));
            admin.getTables(data => {
              resetRestaurants({ restaurants: restaurants, restaurantTables: data.restaurantTables });
            });
            def.resolve();
          } else if (restaurants.length > 0) {
            /*
            admin.getTables(data => {
              resetRestaurants({ restaurants: restaurants, restaurantTables: data.restaurantTables });
              t.setState({ ...t.state, restaurantSelected: sessionStorage.restaurantSelected });
            });
            */
            navigateToLogin();
            def.resolve();
          } else {
            resetRestaurants({ restaurants: restaurants, restaurantTables: [] });
            def.resolve();
          }
        });
      },
      () => {
        console.log("App.js: failed to get status from server");
        console.log("localStorage.server=" + localStorage.server);
        console.log("localStorage.globalId=" + localStorage.globalId);
        if (localStorage.server !== auth.globalserver && typeof localStorage.globalId != "undefined") {
          const s = localStorage.server;
          auth.server = auth.globalserver;
          console.log("SWITCH");
          switchServer({ globalId: localStorage.globalId, serverUrl: s }).done(() => {
            t.props.history.push("/");
          }).fail(() => {
            console.log("FAILED TO LOGIN TO SERVER");
            if (!t.props.history.location.pathname.startsWith("/login")) {
              t.props.history.push("/login");
            }
          });
          return;
        }

        auth.ajaxError = false;
        let local = locals[localStorage.language];
        let admin_local = admin_locals[localStorage.language];
        changeLanguage(local, admin_local, localStorage.language, localStorage.country);
        if (
          t.props.history.location.pathname.startsWith("/consent") ||
          this.props.history.location.pathname.startsWith("/login") ||
          this.props.history.location.pathname.startsWith("/passwordReset") ||
          this.props.history.location.pathname.startsWith("/passwordForgotten") ||
          this.props.history.location.pathname.startsWith("/register")
        )
          return;
        t.props.history.push("/login");
        //t.setState({ showLogin: true });
      }

    );
    return def.promise();
  };

  pickRestaurant(restaurantId, name, languages, url, backupUrl, instance) {

    const originalServer = auth.server;
    const t = this;
    switchServer({ globalId: restaurantId, serverUrl: url, backupUrl: backupUrl, name: name, instance }).done(() => {
      // eslint-disable-next-line no-restricted-globals
      // eslint-disable-next-line no-restricted-globals
      if (window.device.platform === "browser" && Number(location.port) !== 3000 && location.href.indexOf("https://hotbackup.eatwithme.online") === -1) {
        if (originalServer !== auth.server) {
          var u = (url === "" ? auth.globalserver : url) + appRoot.substring(1) + (t.props.history.location.pathname.indexOf("Restaurant.app") !== -1 ? t.props.history.location.pathname.split("Restaurant.app")[1] : "");
          if (u === auth.appServer) {
            t.props.onReload();
            return;
          }
          getString(u).done(() => {
            if (!url)
              url = auth.globalserver;
            else
              url = url.substring(0, url.length - 1)
            // eslint-disable-next-line no-restricted-globals
            location.href = u + "?r=" + sessionStorage.restaurantSelected + "&s=" + url;
          }).fail((error) => {
            auth.ajaxError = false;
            get("login/setRestaurant/" + sessionStorage.restaurantSelected)
              .done(() => {
                t.onReload();
              })
              .fail(() => {
                sessionStorage.restaurantSelected = 0;
                localStorage.server = auth.globalserver;
                // eslint-disable-next-line no-restricted-globals
                location.reload()
              });
          });

        } else {
          get("login/setRestaurant/" + sessionStorage.restaurantSelected).done(() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          });
        }
      } else {
        localStorage.server = auth.server;
        get("login/setRestaurant/" + sessionStorage.restaurantSelected)
          .done(() => {
            // eslint-disable-next-line no-restricted-globals
            t.onReload();
          })
          .fail(() => {
            auth.ajaxError = false;
            sessionStorage.restaurantSelected = 0;
            localStorage.server = auth.globalserver;
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          });
      }
    });
  }

  onReload = () => {
    this.onloadIndexHtml();
  }

  getRestaurants() {
    var def = $.Deferred();
    admin.getRestaurants(function (data) {
      var restaurants = data.restaurants;
      if (!restaurants.length) {
        delete localStorage.globalId;
        delete localStorage.server;
        auth.server = auth.globalserver;
        navigateToLogin();
        return;
      }
      restaurants.forEach(r => r.fromLocal = true);
      if (auth.server !== auth.globalserver && !auth.myStatus.is_global_server) {
        admin.getGlobalRestaurants(
          function (gdata) {
            if (gdata?.restaurants) {
              gdata.restaurants.filter(r => restaurants.filter(rr => rr.globalId === r.globalId).length === 0).map(r => restaurants.push(r));
              gdata.restaurants.forEach(r => {
                r.fromGlobal = false;
                const rest = restaurants.find(rr => rr.globalId === r.globalId);
                rest.serverUrl = r.serverUrl;
                rest.localUrl = r.localUrl;
              });
            }
            def.resolve(restaurants);
          },
          (error) => {
            //auth.ajaxError = false;
            if (error.status === 403) {
              delete localStorage.globalId;
              delete localStorage.server;
              auth.server = auth.globalserver;
              navigateToLogin();
            } else
              def.resolve(restaurants);
          }
        );
      } else {
        def.resolve(restaurants);
      }
    });
    return def.promise();
  }
}

const mapStateToProps = state => {
  return {
    myStatus: state.rootReducer.myStatus,
    ai: state.rootReducer.ai,
    voipphoneSelected: state.rootReducer.voipphoneSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetRestaurants: ({ restaurants, restaurantTables }) => {
      dispatch(EatWithMeActions.updateRestaurants(restaurants));
      dispatch(EatWithMeActions.updateRestaurantTables(restaurantTables));
    },
    changeLanguage: dispatchChangeLanguage(dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withRouter(App)));

import $ from "jquery";
import auth, { post } from "../auth";
import { update } from "../order-list-util";
import { hideEditTable, selectNewOrders, submitNewOrders } from "../tables-overview";
import { store } from "../..";
import EatWithMeActions from "../../actions/EatWithMeActions";

class AI {
  initialized = false;

  setContext(properties) {
    const def = $.Deferred();
    if (auth.myStatus.restaurant_settings.ai && auth.myStatus.restaurant_settings.ai.enabled === true) {
      post("aiService/" + sessionStorage.restaurantSelected + "/setContext", properties).done(data => {
        //console.log(data);
        def.resolve();
      });
    } else {
      def.resolve();
    }

    return def.promise();
  }

  sendCommand(commands) {
    const def = $.Deferred();
    if (auth.myStatus.restaurant_settings.ai && auth.myStatus.restaurant_settings.ai.enabled === true) {
      const ai = this;
      //console.log(commands);
      post("aiService/" + sessionStorage.restaurantSelected + "/commands?lang=" + localStorage.language, commands).done(data => {
        if (!data)
          return;
        console.log(data);
        data.forEach(data => {
          if (Array.isArray(data)) {
            data.forEach(data => {
              if (data.action) {
                ai.processAction(data.action);
              } else if (data.name) {
                ai.processAction(data);
              }
              if (data.tableOccupations) update(data);
              if (data.tableOccupation) update(data);
            });
          } else {
            if (data.action) {
              ai.processAction(data.action);
            } else if (data.name) {
              ai.processAction(data);
            }
            if (data.tableOccupations) update(data);
            if (data.tableOccupation) update(data);
          }
        });
      });
      return def.promise();
    } else {
      def.resolve();
      return def.promise();
    }
  }

  processAction(action) {
    console.log("action.name", action.name);
    switch (action.name) {
      case "select_table":
        const table = $("svg:visible g#" + action.table_number + ".eatwithme-table");
        console.log(table.length);
        table.click();
        break;
      case "close_table":
        hideEditTable(false);
        break;
      case "close_table_to_top":
        hideEditTable(true);
        break;
      case "new_orders":
        selectNewOrders();
        break;
      case "submit_orders":
        if (action.print === false) submitNewOrders("accepted", false);
        else submitNewOrders("accepted");
        break;
      default:
    }
  }

  initSpeach() {
    try {
      if (this.initialized === true) return;

      var ai = this;

      ai.speechRecognitionState = "stopped";
      ai.sendCommandTimeout = null;

      if (auth.myStatus.restaurant_settings && auth.myStatus.restaurant_settings.ai && auth.myStatus.restaurant_settings.ai.enabled === true && window.plugins.speechRecognition) {
        this.initialized = true;
        window.plugins.speechRecognition.isRecognitionAvailable(
          result => {
            auth.myStatus.h = result;
            console.log("speech recognition is available", result);
            if (result === "Missing permission")
              window.plugins.speechRecognition.requestPermission(
                result => {
                  auth.myStatus.h = true;
                  store.dispatch(EatWithMeActions.speechRecognitionAvailable);
                  console.log("speech recognition available:", result);
                },
                () => {
                  auth.myStatus.speechRecognitionAvailable = false;
                  console.log("speech recognition is not available");
                }
              );
            else {
              window.plugins.speechRecognition.hasPermission(
                (result) => {
                  console.log("speech recognition permission", result);
                  if (!result) {
                    console.log("speech recognition no permission yet.", result);
                    window.plugins.speechRecognition.requestPermission(
                      result => {
                        auth.myStatus.h = true;
                        store.dispatch(EatWithMeActions.speechRecognitionAvailable);
                        console.log("speech recognition available:", result);
                      },
                      () => {
                        auth.myStatus.speechRecognitionAvailable = false;
                        console.log("speech recognition is not available");
                      }
                    );
                  } else {
                    auth.myStatus.speechRecognitionAvailable = true;
                    store.dispatch(EatWithMeActions.speechRecognitionAvailable);
                  }
                },
                (result) => {
                  console.log("speech recognition no permission yet.", result);
                  window.plugins.speechRecognition.requestPermission(
                    result => {
                      auth.myStatus.h = true;
                      store.dispatch(EatWithMeActions.speechRecognitionAvailable);
                      console.log("speech recognition available:", result);
                    },
                    () => {
                      auth.myStatus.speechRecognitionAvailable = false;
                      console.log("speech recognition is not available");
                    }
                  );
                }
              );
            }

            if (auth.myStatus.speechRecognitionAvailable === true) {
              $('<div id="speechRecording" class="hidden"></div>').appendTo("body");
            }
          },
          result => {
            console.log("speech recognition is not available: ", result);
          }
        );

        console.log("init speech on shake");

        if (typeof window.shake !== "undefined") window.shake.startWatch(() => ai.onShake(), 40);
      }
    } catch (ex) {
      console.log("exception");
      console.log(ex);
      console.log(ex.stack);
    }
  }

  onShake() {
    const ai = this;
    if (this.speechRecognitionState === "stopped") {
      this.speechRecognitionState = "started";
      this.startSpeechRecognition();
    } else {
      this.stopSpeechListening();
      /*
      window.plugins.speechRecognition.stopListening(
        () => {
          ai.speechRecognitionState = "stopped";
        },
        () => {
          ai.speechRecognitionState = "stopped";
          console.log("stop listening error");
        }
      );
      */
    }
  }

  textReceived(textArray) {
    const ai = this;
    try {
      if (!window.plugins.speechRecognition || !window.plugins.speechRecognition.isStopped) {
        //console.log("window.plugins.speechRecognition.isStopped is not available")
      } else
        window.plugins.speechRecognition.isStopped(result => {
          if (result === true) {
            store.dispatch(EatWithMeActions.speechRecognitionOff);
            console.log("Speech recognition task stopped");
            var commands = [];
            textArray.forEach(text => {
              if (text.indexOf("vége") !== -1) {
                finished = true;
                commands.push(text.split("vége")[0]);
              } else commands.push(text);
            });
            if (ai.sendCommandTimeout != null) {
              clearTimeout(ai.sendCommandTimeout);
            }
            ai.sendCommandTimeout = setTimeout(() => {
              ai.sendCommandTimeout = null;
              console.log("Sending command to AI!");
              console.log(commands);
              store.dispatch(EatWithMeActions.setSpeechCommand(ai.getLastCommand(commands[0])));
              ai.sendCommand(commands);
            }, 100);
          }
        });
      if (textArray == null) {
        return;
      }

      store.dispatch(EatWithMeActions.setSpeechCommand(ai.getLastCommand(textArray[0])));
      var finished = false;
      var commands = [];

      var text = textArray[0];
      //textArray.forEach(text => {
      commands = text.split("vége").filter(a => a);
      if (text.lastIndexOf("vége") !== -1 && text.lastIndexOf("vége") === text.length - 4) {
        finished = true;
      }
      //});

      if (commands.length > 0)
        store.dispatch(EatWithMeActions.setSpeechCommand(commands[commands.length - 1]));

      if (finished) {
        //ai.stopSpeechListening().done(() => {
        this.sendCommands(commands);
        //ai.submitToAI(null, $("form#speechForm"));
        store.dispatch(EatWithMeActions.setSpeechCommand(""));
        //ai.startSpeechListening();
        //});
        /*
        window.plugins.speechRecognition.stopListening(
          () => {
            ai.speechRecognitionState = "stopped";
          },
          () => {
            ai.speechRecognitionState = "stopped";
          }
        );
        */
      }
    } catch (ex) {
      console.log(ex);
      console.log(ex.stack);
    }
  }
  errorReceived(error) {
    const ai = this;
    console.log("Error received");
    console.log(error);
    ai.speechRecognitionState = "stopped";
    store.dispatch(EatWithMeActions.speechRecognitionOff);
    //messageDialog(local.error_message, error.Message);
    $("#speechRecoding").addClass("hidden");
  }

  getLastCommand(command) {
    return command.split("vége").filter(a => a).pop();
  }

  startSpeechRecognition() {
    const options = {
      language: localStorage.language,
      matches: 5,
      showPartial: true
    };
    store.dispatch(EatWithMeActions.speechRecognitionOn);
    try {
      window.plugins.speechRecognition.startListening(
        textArray => this.textReceived(textArray),
        error => this.errorReceived(error),
        options
      );
    } catch (ex) {
      console.log("Exception occured during starting listening: " + ex);
    }
  }

  startSpeechListening() {
    const ai = this;
    ai.commandsSent = "";
    if (ai.speechRecognitionState === "stopped") {
      ai.speechRecognitionState = "started";
      ai.commandsSent = [];
      ai.startSpeechRecognition();
    }
  }

  stopSpeechListening() {
    const def = $.Deferred();
    console.log("stop listening ...");
    const ai = this;
    if (ai.speechRecognitionState !== "stopped") {
      window.plugins.speechRecognition.stopListening(
        (result) => {
          ai.speechRecognitionState = "stopped";
          store.dispatch(EatWithMeActions.speechRecognitionOff);
          ai.submitToAI(null, $("form#speechForm"));
          def.resolve();
        },
        (result) => {
          console.log("stop listening state error", result);
          ai.speechRecognitionState = "stopped";
          store.dispatch(EatWithMeActions.speechRecognitionOff);
          console.log("stop listening error");
          def.reject();
        }
      );
    }
    return def.promise();
  }

  submitToAI(event, form) {
    const command = $(form)
      .find("input#aicommand")
      .val();
    this.sendCommands(command.split("vége").filter(a => a));
    if (event)
      event.preventDefault();
  }

  commandsSent = [];

  sendCommands(commands) {
    var coms = [...this.commandsSent];
    try {
      this.commandsSent.forEach(c => console.log("sent:" + c));
      commands.forEach(command => {
        command = command.trim();
        if (coms.length) {
          if (coms[0] === command) {
            console.log("Command skipped:" + command);
            coms.splice(0, 1);
            return;
          }
        }
        console.log("Command sent:" + command);
        this.sendCommand([command]);
        this.commandsSent.push(command);
      });
    } catch (ex) {
      console.log(ex);
    }
  }
}

export default AI;

import { I18n } from "react-redux-i18n";
import auth from "../auth";
import { getPaymentMethod } from "../tableMatePaid";

export const generate_sam4s = (def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat) => {
    var url = "";
    var sam4s = {
        5: "A00",
        18: "B00",
        27: "C00",
        s5: "A00",
        s18: "B00",
        s27: "C00",
        null: "E00",
        undefined: "E00"
    };


    const prefix = "";
    var services = {};
    var count = 1;

    var totalAmount = 0;

    url = "PTG|1";

    Object.values(amounts).sort((a, b) => a.entryOrder - b.entryOrder).filter(category => category.amount + category.service_amount).forEach(category => {
        if (category.amount) {
            //eslint-disable-next-line no-useless-concat
            url += "\\1|" + category.name + " " + "||" + ("" + category.amount).replace(",", ".") + "|" + category.count + "|db|" + (category.count * category.amount) + "|" + sam4s[category.vat] + "||";
        }
        totalAmount += category.amount;
        count++;
        if (category.service_amount > 0) {
            if (!services[category.vat]) {
                services[category.vat] = 0;
            }
            services[category.vat] += category.service_amount * category.count;
            totalAmount += category.service_amount * category.count;
        }
    });

    Object.keys(services).forEach(key => {
        url += "\\1|" + I18n.t("admin_local.fiscat_service_fee") + " " + key + "%||" + ("" + services[key]).replace(",", ".") + "|1|db|" + ("" + services[key]).replace(",", ".") + "|" + sam4s["s" + key] + "||";
        count++;
    });


    var paymentMethods = {
        "cash": "1",
        "card": "2",
        "hellopay": "2",
        "teya": "2",
        "online": "2",
        "bizpay": "2",
        "paynance": "2",
    }

    types.filter(t => t.amount > 0).forEach((t, ind) => {
        if (ind > 0)
            url += "|";
        const paymentMethod = getPaymentMethod(t.type);

        //var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
        var amount = t.amount;
        // eslint-disable-next-line no-eval
        //amount = eval(roundFunction.replace("amount", t.amount));

        if (t.currency === "EUR" && paymentMethod.method.type === "cash")
            url += "\\6|1|" + Math.round(100 * t.amount) / 100;
        else
            url += "\\6|" + paymentMethods[paymentMethod.method.type] + "|" + amount;
    });

    url += "\r\n";

    if (types.filter(t => t.amount > 0).length === 0) url = "";

    console.log(url);
    def.resolve(url);
}

export const storno_sam4s = (data) => {
    return `PTG|1\\7|0|${data.customerName}|${data.customerPostCode}|${data.customerTown}|${data.customerAddress}|||${data.originalDate}|${data.originalReceipt}|${data.reason}`;
}

export const cashin_sam4s = (reason, amount) => {
    return `PTG|1\\9|0|1|${reason}|${amount}`;
}

export const cashout_sam4s = (reason, amount) => {
    return `PTG|1\\9|1|1|${reason}|${amount}`;
}

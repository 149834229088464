import React, { Component, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LabeledInputStyled from '../widgets/LabeledInputStyled';
import { I18n } from 'react-redux-i18n';
import "../css/customerDeliveryDetails.css"
import { getFeature, getHomeDeliveryTableNumber, getPickupTableNumber, getUsersContaining, saveSetting, unlockTable } from '../js/admin';
import { useTable, usePagination } from "react-table";
import styled from 'styled-components'
import FlexGrowSpace from '../widgets/FlexGrowSpace';
import { DateTimePickerInput, TimePickerInput } from 'react-datetime-range-super-picker'
import 'react-datetime-range-super-picker/dist/index.css'
import moment from "moment";
import { getPaymentMethod } from "../js/tableMatePaid.js";
import LabeledRadioButtonsStyled from '../widgets/LabeledRadioButtonsStyled';
import { Map as MapContainer, Marker, Polygon, Popup, TileLayer, Tooltip, useLeaflet as useMapEvents } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import LabeledAutoCompleteStyled from '../widgets/LabeledAutocompleteStyled';
import removeAccents from "remove-accents";
import { postcodes } from '../js/postcodes';
import auth, { confirmDialog, featureEnabled, get, getSetting, messageDialog } from '../js/auth';
import L from 'leaflet';
import { changeToTable, hideEditTable, hideSelectNewOrders } from '../js/tables-overview.js';
import { connect } from 'react-redux';
import { store } from '../index.js';
import EatWithMeActions from '../actions/EatWithMeActions.js';
import orderListUtil, { update } from '../js/order-list-util.js';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaGripLines, FaEye, FaEyeSlash } from "react-icons/fa";

const provider = new OpenStreetMapProvider();

const defaultOrder = [
    "email",
    "surname",
    "name",
    "user_comment",
    "postcode",
    "town",
    "address",
    "door",
    "country",
    "phone",
    "comment",
];

class ReorderableForm extends React.Component {
    constructor(props) {
        super(props);
    }



    onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) return;

        const newOrder = Array.from(this.props.order);
        const [movedItem] = newOrder.splice(source.index, 1);
        newOrder.splice(destination.index, 0, movedItem);

        this.props.saveSettingsToLocalStorage(newOrder, this.props.visibility);
    };

    // Toggle field visibility
    toggleFieldVisibility = (fieldId) => {
        this.props.saveSettingsToLocalStorage(
            this.props.order, {
            ...this.props.visibility,
            [fieldId]: !this.props.visibility[fieldId],
        }
        )
    };

    // Reset order and visibility to defaults

    showField = (fieldId) => {
        if (this.props.type === "pickup") {
            switch (fieldId) {
                case "postcode":
                case "town":
                case "address":
                case "door":
                case "country":
                    return false;
            }
        }
        return true;
    }

    renderField = (fieldId) => {
        const commonProps = {
            className: "labeled-input table-style",
            change: this.props.onChange,
        };

        switch (fieldId) {
            case "email":
                return <LabeledInputStyled key="email" id="email" value={this.props.email} label={I18n.t("local.email")} type="text" {...commonProps} />;
            case "surname":
                return <LabeledInputStyled key="surname" id="surname" value={this.props.surname} label={I18n.t("local.surname")} type="text" {...commonProps} />;
            case "name":
                return <LabeledInputStyled key="name" id="name" value={this.props.name} label={I18n.t("local.first_name")} type="text" {...commonProps} />;
            case "user_comment":
                return (
                    <LabeledInputStyled
                        key="user_comment"
                        id="user_comment"
                        value={this.props.user_comment}
                        label={I18n.t("admin_local.user_comment")}
                        type="text"
                        tooltip={I18n.t("admin_local.tooltip.user_comment")}
                        {...commonProps}
                    />
                );
            case "postcode":
                return (
                    <LabeledInputStyled
                        key="postcode"
                        id="postcode"
                        value={this.props.postcode}
                        valid={this.props["postcode_valid"]}
                        label={I18n.t("local.postcode")}
                        type="text"
                        pattern={I18n.t("admin_local.postcode_pattern")}
                        {...commonProps}
                    />
                );
            case "town":
                return <LabeledInputStyled key="town" id="town" value={this.props.town} label={I18n.t("local.town")} type="text" {...commonProps} />;
            case "address":
                return (
                    <LabeledAutoCompleteStyled
                        key="address"
                        id="address"
                        value={this.props.address}
                        valid={this.props["address_valid"]}
                        options={this.props.address_choices}
                        placeholder={I18n.t("admin_local.address_placeholder")}
                        pattern={I18n.t("admin_local.address_pattern")}
                        label={I18n.t("local.address")}
                        type="text"
                        tooltip={I18n.t("admin_local.tooltip.address")}
                        onSelect={this.props.onSelect}
                        {...commonProps}
                    />
                );
            case "door":
                return <LabeledInputStyled key="door" id="door" value={this.props.door} label={I18n.t("local.door")} type="text" {...commonProps} />;
            case "country":
                return <LabeledInputStyled key="country" id="country" value={this.props.country} label={I18n.t("local.country")} type="text" {...commonProps} />;
            case "phone":
                return (
                    <LabeledInputStyled
                        key="phone"
                        id="phone"
                        value={this.props.phone}
                        pattern="(^$)|(\/(.*))|((06|\+36|[\/ -]?\+[\/ -]?\d[\/ -]?\d[\/ -]?\d?)[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d?)"
                        label={I18n.t("local.phone")}
                        type="text"
                        {...commonProps}
                    />
                );
            case "comment":
                return (
                    <LabeledInputStyled
                        key="comment"
                        id="comment"
                        value={this.props.comment}
                        label={I18n.t("admin_local.booking_comment")}
                        type="text"
                        tooltip={I18n.t("admin_local.tooltip.comment")}
                        {...commonProps}
                    />
                );
            default:
                return null;
        }
    };


    render() {
        return (
            <div>

                {/* Drag-and-Drop Form */}
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="formFields">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {this.props.order.map(
                                    (fieldId, index) =>
                                        this.showField(fieldId) &&
                                        this.props.visibility[fieldId] && (
                                            <Draggable key={fieldId} draggableId={fieldId} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {this.props.rearrangeMode && (
                                                            <div
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    cursor: "grab",
                                                                    color: "#aaa",
                                                                }}
                                                            >
                                                                <FaGripLines />
                                                            </div>
                                                        )}
                                                        {this.renderField(fieldId)}
                                                        {this.props.rearrangeMode && (
                                                            <button
                                                                onClick={() => this.toggleFieldVisibility(fieldId)}
                                                                style={{
                                                                    marginLeft: "auto",
                                                                    border: "none",
                                                                    background: "none",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {this.props.visibility[fieldId] ? <FaEyeSlash /> : <FaEye />}
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}


const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  width: 100%;
  height: 100%;
  display:flex;
  flex-flow:column;
  

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    height:100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid lightgray;
    flex-grow:1;
    flex-shrink:1;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid lightgray;
      border-right: 1px solid lightgray;

      /* The secret sauce */
      /* Each cell should grow equally */
      /*width: 1%;*/
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`


function Table({ id, columns, data, onClick, onDelete, onDeleteCondition }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data
    }, usePagination)

    if (headerGroups == null)
        return null;

    return (
        <Styles>
            <div id={id}>
                <div className="tableWrap">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps({
                                                className: column.collapse ? 'collapse' : '',
                                            })}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                    {onDelete && <th />}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} >
                                        {row.cells.map(cell => {
                                            return (
                                                <React.Fragment>
                                                    <td onClick={() => onClick(row.original)}
                                                        {...cell.getCellProps({
                                                            className: cell.column.collapse ? 'collapse' : '',
                                                        })}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                </React.Fragment>
                                            )
                                        })}
                                        {onDelete && (!onDeleteCondition || onDeleteCondition(row.original)) ? (<td style={{ width: "3rem" }}><Button onClick={() => onDelete(row.original)} className="btn btn-negative icon-cancel" /></td>) : (<td />)}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
            </div>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </Styles>
    )

}

function History({ columns, data, onClick }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data
    }, usePagination)

    if (headerGroups == null)
        return null;

    return (
        <Styles>
            <div className="tableWrap">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps({
                                            className: column.collapse ? 'collapse' : '',
                                        })}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} onClick={() => onClick && onClick(row.original)}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({
                                                    className: cell.column.collapse ? 'collapse' : '',
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
            </div>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </Styles>
    )

}


var map;

function MyComponent({ t }) {
    const position = [t.state.latitude, t.state.longitude];
    map = useMapEvents({
    })
    const isPos = !!(position[0] && position[1]);
    const mainMarker = isPos ?
        <Marker className="selectedAddress" position={position} icon={L.divIcon({
            html: '<img src="https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png"/>',
            className: 'selectedAddress', // Custom class for styling
            iconSize: [25, 41], // Optional size of the icon
            iconAnchor: [12, 41],
        })}>
            <Tooltip className="selectedAddress" permanent direction="right" offset={[10, 0]} >
                <div>
                    {t.state.address}
                </div>
            </Tooltip>
        </Marker>
        : null;

    const { addresses, address: selectedAddress } = t.state;

    const bounds = [];
    if (isPos) {
        bounds.push(position);
        bounds.push(position);
    }
    if (addresses && isPos) {
        addresses
            .filter(address => address.latitude && address.longitude && address.address !== selectedAddress)
            .forEach(address => {
                bounds[0] = [Math.min(bounds[0][0], address.latitude), Math.max(bounds[0][1], address.longitude)];
                bounds[1] = [Math.max(bounds[1][0], address.latitude), Math.min(bounds[1][1], address.longitude)];
            });
    }

    // Fit map to all markers
    if (t.state.newaddress && bounds.length > 0) {
        const centerLat = (Number(bounds[0][0]) + Number(bounds[1][0])) / 2;
        const centerLng = (Number(bounds[0][1]) + Number(bounds[1][1])) / 2;
        // Calculate zoom based on the bounds' span
        const latDiff = bounds[1][0] - bounds[0][0];
        const lngDiff = bounds[0][1] - bounds[1][1];

        const margin = 100; // 30px margin
        const center = map.map.getCenter();
        const zoom = map.map.getZoom();
        // Calculate a more precise zoom level
        const containerWidth = map.map.getSize().x - 2 * margin;
        const containerHeight = map.map.getSize().y - 2 * 30;
        const worldMapWidth = 256; // Base tile width in pixels
        const zoomLat = Math.log2((worldMapWidth * (containerHeight / 256)) / latDiff);
        const zoomLng = Math.log2((worldMapWidth * (containerWidth / 256)) / lngDiff);
        const calculatedZoom = Math.floor(Math.min(zoomLat, zoomLng, 14)); // Choose the smaller zoom for full bounds, with max zoom 18

        const dlat = Math.abs(center.lat - centerLat);
        const dlng = Math.abs(center.lng - centerLng);

        if (center.lat != centerLat || center.lng != centerLng || zoom !== calculatedZoom) {
            t.setState({ newaddress: false, center: [centerLat, centerLng], zoom: calculatedZoom })
            map.map.setView([centerLat, centerLng], calculatedZoom);
        }
    }

    return <React.Fragment>
        {mainMarker}
        {addresses && addresses.filter(address => address.latitude && address.longitude && address.address !== selectedAddress).map(address => (
            <Marker position={[address.latitude, address.longitude]} icon={L.divIcon({
                html: '<img src="https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png"/>',
                className: '',
                iconSize: [25, 41], // Optional size of the icon
                iconAnchor: [12, 41],
            })}>

                <Tooltip permanent direction="right" offset={[10, 0]} >
                    <div onClick={(e) => { t.customerAddressSelected(address) }} >
                        {address.address}
                    </div>
                </Tooltip>
            </Marker>
        ))}
    </React.Fragment>;
}

const _state = {
    email: "",
    name: "",
    surname: "",
    phone: "",
    postcode: "",
    door: "",
    address: "",
    address_choices: [],
    center: [0, 0],
    initialized: false,
}

class CustomerDeliveryDetails extends Component {

    constructor(props) {
        super(props);
        this.startDate = React.createRef();
        this.targetRef = React.createRef();

        this.state = {
            noorders: this.getAnyOrdersForOccupation(sessionStorage.tableOccupationSelected)?.length === 0,
            show: true,
            search: "",
            customers: [],
            focus: undefined,
            startTimeModified: false,
            zoom: 13,
            orderAreas: auth.myStatus.restaurant_settings["enabled-features"].homedelivery.price ? auth.myStatus.restaurant_settings["enabled-features"].homedelivery.price.filter(p => p.area).map(p => JSON.parse(p.area)) : [],
            center: [props.myStatus.restaurant_latitude || 0, this.props.myStatus.restaurant_longitude || 0]
        }

        if (props.customerSelected) {
            if (props.customerSelected.id)
                this.customerSelected({
                    id: props.customerSelected.id,
                    email: props.customerSelected.email,
                    phone: props.customerSelected.phone,
                    name: props.customerSelected.name,
                    surname: props.customerSelected.surname,
                    user_comment: props.customerSelected.comment,
                    address: props.customerSelected.address,
                    postcode: props.customerSelected.postcode,
                    town: props.customerSelected.town,
                    latitude: props.customerSelected.latitude,
                    longitude: props.customerSelected.longitude,
                    door: props.customerSelected.door,
                });
            else {
                this.state.phone = props.customerSelected.phone;
            }
            store.dispatch(EatWithMeActions.phoneend(props.customerSelected.phone));
        }



    }

    componentDidUpdate = (prevProps) => {
        if (this.props.type !== prevProps.type) {
            const defaultVisibility = defaultOrder.reduce((acc, field) => {
                acc[field] = true;
                return acc;
            }, {});

            const setting = JSON.parse(getSetting("enabled-features." + this.state.type + ".fieldsSettings"))

            this.setState({
                order: setting?.order || defaultOrder,
                visibility: setting?.visibility || defaultVisibility,
            });
        }
    }

    componentWillUnmount() {
        this.disconnectObserver();
    }

    // Toggle rearrange mode
    toggleRearrangeMode = () => {
        this.setState((prevState) => ({
            rearrangeMode: !prevState.rearrangeMode,
        }));
    };

    resetSettings = () => {
        const defaultOrder = [
            "email",
            "surname",
            "name",
            "user_comment",
            "postcode",
            "town",
            "address",
            "door",
            "country",
            "phone",
            "comment",
        ];
        const defaultVisibility = defaultOrder.reduce((acc, field) => {
            acc[field] = true;
            return acc;
        }, {});
        this.setState({ order: defaultOrder, visibility: defaultVisibility }, this.saveSettingsToLocalStorage);
    };

    // Save order and visibility to localStorage
    saveSettingsToLocalStorage = (order, visibility) => {
        saveSetting("enabled-features/" + this.state.type + "/fieldsSettings", { order: order, visibility: visibility });
        getSetting("enabled-features." + this.state.type).fieldsSettings = JSON.stringify({ order: order, visibility: visibility });
        this.setState({ order: order, visibility: visibility });
    };


    setupIntersectionObserver() {
        if (this.observer)
            return;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        this.observer = new IntersectionObserver(this.handleIntersection, options);

        if (this?.targetRef?.current) {
            this.observer.observe(this.targetRef.current);
        }
    }

    disconnectObserver() {
        if (this.observer && this?.targetRef?.current) {
            this.observer.unobserve(this.targetRef.current);
        }
    }

    handleIntersection = (entries) => {
        entries.forEach((entry) => {
            const isVisible = this.state.isVisible || entry.isIntersecting;

            this.setState({ isVisible });

            if (isVisible && this.props.onVisible) {
                this.props.onVisible();
            }
        });
    };

    componentDidMount = () => {
        this.setupIntersectionObserver();
        if (this.state.id && !this.initialized2) {
            this.initialized2 = true;
            this.customerSelected({ ...this.state, id: this.state.id });
            this.customerSelected({ ...this.state, id: this.state.id });
        }
    }

    componentDidUpdate = () => {
        this.setupIntersectionObserver();
        if (this.state.id && !this.initialized2) {
            this.initialized2 = true;
            this.customerSelected({ ...this.state, id: this.state.id });
            this.customerAddressSelected({ ...this.state, id: this.state.id }, false);
        } else {
            this.initialized2 = true;
        }
    }

    componentWillUnmount = () => {
        this.initialized2 = false;
        this.setState({ ..._state });
    }


    static getDerivedStateFromProps(props, state) {
        const tableNumber = Number(sessionStorage.tableNumberSelected);
        const timeahead = getPickupTableNumber() === tableNumber ? getFeature("pickup").timeahead : getHomeDeliveryTableNumber() === tableNumber ? getFeature("homedelivery").timeahead : 0;
        const minduration = getPickupTableNumber() === tableNumber ? 0 : getFeature("homedelivery")["min-duration"];
        const payment_methods = getPickupTableNumber() === tableNumber ? getFeature("pickup").payment_method : getHomeDeliveryTableNumber() === tableNumber ? getFeature("homedelivery").payment_method : [];
        const type = getPickupTableNumber() === tableNumber ? "pickup" : getHomeDeliveryTableNumber() === tableNumber ? "homedelivery" : "";
        const allowed_installments_setup = type ? props.myStatus.restaurant_settings["enabled-features"][type].allowed_installments : [];
        const allowed_installments = allowed_installments_setup ? Object.keys(allowed_installments_setup).filter(m => allowed_installments_setup[m]) : [];
        var s = {
            ..._state,
            ...props.values,
            ...state
        }

        const defaultVisibility = defaultOrder.reduce((acc, field) => {
            acc[field] = true;
            return acc;
        }, {});
        var setting = {};

        try {
            setting = JSON.parse(getSetting("enabled-features." + type + ".fieldsSettings"))
        } catch (e) { }

        if (setting?.order?.order)
            setting = {}

        s = {
            ...s,
            order: setting?.order || defaultOrder,
            visibility: setting?.visibility || defaultVisibility,
            minduration: minduration,
            timeahead: timeahead,
            date: moment().add("hours", timeahead),
            type: type,
            start: state.start ? state.start : props.values && props.start ? moment(props.start) : moment().add('hour', timeahead),
            end: state.end ? state.end : props.values && props.end ? moment(props.end) : moment().add(timeahead, 'hour').add(minduration, "hour"),
            payment_method: state.payment_method ? state.payment_method : props.values && props.values.payment_method ? props.values.payment_method : undefined,
            payment_methods: payment_methods,
            allowed_installments: allowed_installments,
            installments_strategy: state.installments_strategy ? state.installments_strategy : props.values && props.values.installments_strategy ? props.values.installments_strategy : undefined,
            town: getPickupTableNumber() === tableNumber ? '' : state.town !== undefined ? state.town : props.values && props.values.town ? props.values.town : props.myStatus.restaurant_town,
            country: state.country !== undefined ? state.country : props.values && props.values.country ? props.values.country : props.myStatus.restaurant_country,
            latitude: state.latitude || (props.values && props.values.latitude !== "undefined" && props.values.latitude),
            longitude: state.longitude || (props.values && props.values.longitude !== "undefined" && props.values.longitude),
            door: "" + (state.initialized ? (s.door !== null ? s.door : "") : (props.values && props.values.door ? props.values.door : (s.door !== null ? s.door : ""))),
            initialized: true,
        };
        return s;
    }

    componentDidMount = () => {
        this.setState({ focus: "search" })
    }


    render() {

        return (
            <Modal key="customer-delivery-details" id="customer-delivery-details" show={this.state.show} onHide={this.props.unmount}>
                <ModalHeader>
                    <span onClick={() => changeToTable('homedelivery').done(() => this.setState({ type: "homedelivery" }))} className={"btn btn-positive homedelivery " + (this.state.type === "homedelivery" ? "active" : "")}>{I18n.t("admin_local.homedelivery")}</span>
                    <span onClick={() => changeToTable('pickup').done(() => this.setState({ type: "pickup" }))} className={"btn btn-positive pickup " + (this.state.type === "pickup" ? "active" : "")}>{I18n.t("admin_local.pickup")}</span>
                    <div style={{ flexGrow: 1 }} />
                    <h2>{I18n.t("admin_local.customer_details")}</h2>
                    <div style={{ flexGrow: 1 }} />
                    {/* Global Toggle Button */}
                    <button className="btn btn-primary icon-cog" onClick={this.toggleRearrangeMode} style={{ marginBottom: "15px" }}>
                        {this.state.rearrangeMode ? I18n.t("admin_local.exit_rearrange_mode") : I18n.t("admin_local.rearrange_fields")}
                    </button>

                    {this.state.rearrangeMode && (
                        <button className="btn btn-primary" onClick={this.resetSettings} style={{ marginBottom: "15px", marginLeft: "10px" }}>
                            {I18n.t("admin_local.reset_to_defaults")}
                        </button>
                    )}
                </ModalHeader >
                <ModalBody key="modalBody">
                    <div key="modalBodyDiv" className="data">
                        {this.state.focus === "date" ?
                            <div className="dateRangePicker">
                                <button style={{ width: "100%" }} type="dismiss" className="btn btn-primary" onClick={this.unfocusDateTime}>{I18n.t("admin_local.next")} </button>
                            </div>
                            : <div id="mapContainer">
                                <div >
                                    <div className="labeled-input table-style" style={{ display: "flex", alignItems: "center", height: "3rem" }}>
                                        <label>{I18n.t("admin_local.datetime")}</label>
                                        <div ref={this.startDate} style={{ display: "flex", alignItems: "center" }}>
                                            <DateTimePickerInput use24hours={true} className="datePicker" format="yyyy-MM-dd           HH:mm" timeFormat="HH:mm" weekStartsOn={1} date={this.state.start.toDate()} onDateTimeUpdate={this.handleFromDateUpdate} />
                                        </div>
                                        {this.state.type !== "pickup" ? (
                                            this.state.startTimeModified ?
                                                <React.Fragment>-<TimePickerInput className="timePicker" format={"HH:mm"} weekStartsOn={1} time={this.state.end.format("HH:mm")} onTimeUpdate={this.handleToDateUpdate} /></React.Fragment>
                                                :
                                                <React.Fragment>-<input onClick={this.setStartDateMessage} style={{ border: "none", outline: "none", fontSize: "1.5rem", height: "2.5em", padding: ".2em 1.2em .2em .5em", borderRadius: "0.417em", backgroundColor: "#f7f7f7", color: "#6c6b6b" }} readOnly value={this.state.end.format("HH:mm")} /></React.Fragment>
                                        ) : null}
                                    </div>
                                    {this.state.payment_methods.length > 0 ?
                                        <LabeledRadioButtonsStyled setValue={this.setValue} key="11" className={(this.state.errorField === 'payment_method' ? 'highlight-3' : '') + ' labeled-input table-style'} id="payment_method" value={this.state.payment_method} type="string" label={I18n.t("admin_local.payment_method")} >
                                            {this.state.payment_methods ? this.state.payment_methods.map(method =>
                                                <div key={method.meth} id={method.meth} value={getPaymentMethod(method.meth).method.name} />
                                            ) : null}
                                        </LabeledRadioButtonsStyled> : null}
                                    {featureEnabled("ordering") && featureEnabled("ordering/menuorder") && this.state.allowed_installments.length ?
                                        <LabeledRadioButtonsStyled setValue={this.setValue} key="12" className={(this.state.errorField === 'installments_strategy' ? 'highlight-3' : '') + ' labeled-input table-style'} id="installments_strategy" value={this.state.installments_strategy} type="string" label={I18n.t("admin_local.installments_strategy")} >
                                            {this.state.allowed_installments.map(method =>
                                                <div key={method} id={method} value={I18n.t("admin_local.allowed_installments." + method)} />
                                            )}
                                        </LabeledRadioButtonsStyled> : null}
                                    <ReorderableForm {...this.props} {...this.state} onChange={this.onChange} onSelect={this.addressSelected} saveSettingsToLocalStorage={this.saveSettingsToLocalStorage} />
                                </div>
                                <div ref={this.targetRef}>
                                    {this.state.isVisible && this.state.type !== "pickup" ?
                                        <div style={{
                                            flexBasis: "50%", width: "100%", height: "calc(100% - 20px)", position: "relative", overflow: "hidden"
                                        }}>
                                            <MapContainer ondblclick={event => this.autofill({ longitude: event.latlng.lng, latitude: event.latlng.lat })} onclick={event => this.clickHandler({ longitude: event.latlng.lng, latitude: event.latlng.lat })} style={{ width: "100%", height: "100%", margin: "10px" }} center={this.state.center} zoom={this.state.zoom} scrollWheelZoom={true} attributionControl={false} >
                                                <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" id="mapbox.streets" maxZoom="18" accessToken="pk.eyJ1IjoiY3N6YXN6IiwiYSI6ImNqeHhqMTY1NDAwM2EzbW83aTJqZGlkM3MifQ.QeTnxqdNsSXLkBtBHsgkBQ" />
//                                                <TileLayer url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=x8FJsqs3fWmMG1ukRfJj" attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a>' />
//                                                <TileLayer url="https://maps.geoapify.com/v1/tile/osm-carto/{z}/{x}/{y}.png?apiKey=bdf8b523e6f44c4587109985a99c240a" attribution='&copy; <a href="https://www.geoapify.com/">Geoapify</a>' />

                                                <MyComponent t={this} />
                                                {this.state.orderAreas.map((f, ind) => f.map(f => {
                                                    return f.geometry.coordinates.map(f => {
                                                        return (<Polygon key={ind} positions={f.map(f => [f[1], f[0]])} color="yellow" />)
                                                    });
                                                }))}

                                            </MapContainer>
                                            {
                                                this.state.addresses && this.state.addresses.length ?
                                                    <Table id="addresses"
                                                        data={this.state.addresses}
                                                        columns={[
                                                            {
                                                                Header: I18n.t("admin_local.more_addresses"),
                                                                accessor: customer => (customer.postcode ? customer.postcode + " " : "") + (customer.town ? customer.town + ", " : "") + (customer.address ? customer.address : "") + " " + (customer.door ? I18n.t("local.door") + ": " + customer.door : "")
                                                            }
                                                        ]}
                                                        onClick={this.customerAddressSelected}
                                                        onDelete={this.customerAddressDeleted}
                                                    >
                                                    </Table> : null}
                                        </div>
                                        : null}
                                </div>
                            </div>
                        }
                    </div>
                    {
                        this.state.focus !== "date" ?
                            <div className="table">
                                {
                                    this.state.address && this.state.tableOccupations && this.state.tableOccupations.length ?
                                        <History
                                            data={this.state.tableOccupations}
                                            columns={[
                                                {
                                                    Header: I18n.t("admin_local.date"),
                                                    accessor: "date"
                                                },
                                                {
                                                    Header: I18n.t("admin_local.type"),
                                                    accessor: "type"
                                                },
                                                {
                                                    Header: I18n.t("admin_local.amount"),
                                                    accessor: "amount"
                                                },
                                                {
                                                    Header: I18n.t("admin_local.status"),
                                                    accessor: "status"
                                                },
                                                {
                                                    Header: I18n.t("admin_local.comment"),
                                                    accessor: "comment"
                                                }

                                            ]}
                                        >
                                        </History> :
                                        this.state.customers && this.state.customers.length > 0 ?
                                            <Table
                                                data={this.state.customers}
                                                columns={[
                                                    {
                                                        Header: I18n.t("local.email"),
                                                        accessor: "email"
                                                    },
                                                    {
                                                        Header: I18n.t("local.surname"),
                                                        accessor: "surname"
                                                    },
                                                    {
                                                        Header: I18n.t("local.first_name"),
                                                        accessor: "name"
                                                    },
                                                    {
                                                        Header: I18n.t("local.address"),
                                                        accessor: customer => (customer.postcode ? customer.postcode + " " : "") + (customer.town ? customer.town + ", " : "") + (customer.address ? customer.address : "") + " " + (customer.door ? I18n.t("local.door") + ": " + customer.door : "")
                                                    },
                                                    {
                                                        Header: I18n.t("local.phone"),
                                                        accessor: "phone"
                                                    },
                                                    {
                                                        Header: I18n.t("admin_local.comment"),
                                                        accessor: "comment"
                                                    }
                                                ]}
                                                onDelete={this.deleteCustomer}
                                                onDeleteCondition={(row) => !row.email}
                                                onClick={this.customerSelected}
                                            >
                                            </Table> : null
                                }
                            </div > : null
                    }
                </ModalBody >
                <ModalFooter>
                    {this.state.type === "pickup" || this.state.type === "homedelivery" ?
                        <React.Fragment>
                            <button type="dismiss" className="btn btn-danger" onClick={this.cancel}>{I18n.t("local.cancel")} </button>
                            {this.state.noorders ?
                                <button type="dismiss" className="btn btn-danger" onClick={this.unmount}>{I18n.t("local.continue")} </button>
                                : null}
                        </React.Fragment>
                        :
                        <button type="dismiss" className="btn btn-danger" onClick={this.props.unmount}>{I18n.t("local.cancel")} </button>}
                    <FlexGrowSpace />
                    {!this.state.email ?
                        <button className="btn btn-primary" onClick={this.setCustomerDetailsUpdate}>{I18n.t("admin_local.save_update_address")}</button>
                        : null}
                    <button className="btn btn-primary" onClick={this.setCustomerDetails}>{I18n.t("admin_local.save")}</button>
                </ModalFooter>
            </Modal >
        );
    }

    clickHandler = center => {
        this.setState({ clicked: true })
        setTimeout(() => {
            if (this.state.clicked) {
                this.setState({ ...center, clicked: false });
            }
        }, 200)

    }

    unmount = () => {
        this.props.onsave(this.state);
        //this.props.unmount();
    }

    getOrdersForOccupation = (id) => {
        if (orderListUtil.ordersCache) {
            return orderListUtil.ordersCache.filter(o => o.tableOccupationId == id && o.recordState === "ACTIVE" && o.state != "canceled" && o.state != "selected" && o.state != "ordered");
        }
        return [];
    }
    getAnyOrdersForOccupation = (id) => {
        if (orderListUtil.ordersCache) {
            return orderListUtil.ordersCache.filter(o => o.tableOccupationId == id && o.recordState === "ACTIVE");
        }
        return [];
    }

    cancel = () => {
        this.props.unmount();
        if (this.state.noorders) {
            hideSelectNewOrders();
            hideEditTable(false);
            //hideEditTable(false);
            unlockTable(sessionStorage.tableOccupationSelected, sessionStorage.tableNumberSelected, true, data => {
                update(data);
            });
        }

    }

    setValue = (key, value) => {
        const state = {};
        state[key] = value;
        this.setState(state)
    }

    focusDateTime = () => {
        this.setState({ focus: "date" })
    }

    unfocusDateTime = () => {
        this.setState({ focus: "search" })
    }

    setStartDateMessage = () => {
        const instance = this;
        messageDialog(I18n.t('local.warning'), I18n.t('admin_local.first_modify_start_date')).done(() => {
            instance.startDate.current.firstElementChild.firstElementChild.dispatchEvent(new Event('focus'));
        });

    }

    startTimeSet = () => {
        this.setState({ startTimeModified: true });
    }

    handleFromDateUpdate = ({ date }) => {
        if (!moment(date.date).isBefore(this.state.date)) {
            const start = moment(date.date);
            //var end = this.state.end;
            //if (end.isBefore(start) || end.format('YYYY-MM-DD') !== start.format('YYYY-MM-DD')) {
            const end = start.clone().add(this.state.minduration, "hour");
            //}
            this.setState({ start: start, end: end, startTimeModified: true })
        } else if (Number(moment(date.date).format("HH")) < 13 && moment(date.date).isBefore(moment())) {
            const start = moment(date.date).add(12, 'hour');
            const end = start.clone().add(this.state.minduration, "hour");
            this.setState({ start: start, end: end, startTimeModified: true })
        } else {
            const start = moment().add(this.state.timeahead, "hour");
            const end = start.clone().add(this.state.minduration, "hour");
            this.setState({ start: start, end: end, startTimeModified: true })
        }
    }
    handleToDateUpdate = ({ time }) => {
        var end = this.state.start.clone().startOf('day').add(time.hour24, "hour").add(time.minute, "minute");
        console.log(end);
        if (end.isAfter(this.state.start)) {
            this.setState({ end: end })
        } else if (Number(end.format("HH")) < 13 && end.clone().add(12, 'hour').isAfter(this.state.start)) {
            end.add(12, 'hour');
            console.log(end);
            this.setState({ end: end })
        }
    }

    setCustomerDetailsUpdate = () => {
        this._setCustomerDetails(true);
    }
    setCustomerDetails = () => {
        this._setCustomerDetails(false);
    }


    _setCustomerDetails = (update) => {
        const state = { ...this.state, update };
        if (this.state.payment_methods.length > 0 && !this.state.payment_method) {
            this.setState({ errorField: "" });
            setTimeout(() => { this.setState({ errorField: "payment_method" }); }, 100);
        } else if (featureEnabled("ordering") && featureEnabled("ordering/menuorder") && this.state.allowed_installments.length && !this.state.installments_strategy) {
            this.setState({ errorField: "" });
            setTimeout(() => { this.setState({ errorField: "installments_strategy" }); }, 100);
        } else if (this.state.type === "homedelivery" && !this.state.address) {
            this.setState({ errorField: "" });
            setTimeout(() => { this.setState({ errorField: "address" }) }, 100);
            //} else if ((this.state.type === "homedelivery" || this.state.type === "pickup") && !this.state.phone) {
            //            this.setState({ errorField: "" });
            //            setTimeout(() => { this.setState({ errorField: "phone" }) }, 100);
        } else {
            const errors = Object.keys(this.state).filter(k => (/_valid$/.test(k)) && this.state[k] === false);
            if (errors.length) {
                this.setState({ errorField: "" });
                setTimeout(() => { this.setState({ errorField: errors[0].split("_")[0] }) }, 100);
                return;
            }
            delete this.state.customers;
            store.dispatch(EatWithMeActions.phoneremove(state.phone));
            this.props.onsave(state);
        }
    }

    deleteCustomer = (row) => {
        confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.do_you_really_delete_the_customer")).done(() => {
            get("adminService/" + sessionStorage.restaurantSelected + "/deleteCustomer/" + row.id).done(data => {
                this.setState({ customers: this.state.customers.filter(c => c !== row) })
            });
        })
    }

    customerSelected = (row) => {
        row.user_comment = row.comment;
        delete row.comment;
        if (this.state.type === 'pickup') {
            delete row.address;
            delete row.town;
            delete row.postcode;
            delete row.country;
            delete row.door;
            const rowcopy = { ...row };
            this.setState({ newaddress: true, ...rowcopy, errorField: undefined, phone_valid: true, tid: row.tid });
        } else {
            get("customerService/getAddresses/" + row.id).done(data => {
                const addresses = data.map(JSON.parse).map(c => {
                    return { address: c.address, door: c.door, latitude: c.latitude, longitude: c.longitude, postcode: c.postcode, town: c.town, tid: c.tid }
                });
                const addresses2 = [];
                addresses.filter(a => a.address).forEach(a => {
                    if (addresses2.find(b => a.address === b.address && a.door === b.door && a.postcode === b.postcode && a.town === b.town))
                        return;
                    addresses2.push(a);
                })

                const rowcopy = { ...row };
                if (row.longitude && row.latitude)
                    this.updateMap(row);

                this.setState({ newaddress: true, ...rowcopy, errorField: undefined, phone_valid: true, id: row.id, addresses: addresses2 });

                const addressSelected = addresses2.find(a => a.latitude == row.latitude && a.longitude == row.longitude);

                if (addressSelected) {
                    this.customerAddressSelected(addressSelected, false);
                }

            });
            get("customerService/getBookings/" + row.id, undefined, undefined, undefined, false).done(data => {
                this.setState({ newaddress: true, ...row, tableOccupations: this.convert(data) });
                if (!row.longitude && !row.latitude)
                    this.updateMap(row);
            }).fail(() => { auth.ajaxError = false; });

        }
    }

    customerAddressSelected = (row, clicked = true) => {
        if (clicked)
            this.setState({ clicked: false });
        /*
        if (row.address === this.state.address) {
    
        };*/
        if (this.state.type === 'pickup') {
            delete row.address;
            delete row.town;
            delete row.postcode;
            delete row.country;
            delete row.door;
        }
        this.setState({ newaddress: true, ...row, searchedId: this.state.id });
        if (this.state.id !== this.state.id)
            get("customerService/getBookings/" + this.state.id, undefined, undefined, undefined, false).done(data => {
                this.setState({ newaddress: true, ...row, tableOccupations: this.convert(data) });
                if (!row.longitude && !row.latitude)
                    this.updateMap(row);
            }).fail(() => { auth.ajaxError = false; });
    }
    customerAddressDeleted = (row, clicked = true) => {
        get("customerService/" + sessionStorage.restaurantSelected + "/invalidateAddress/" + row.tid, undefined, undefined, undefined, false).done(data => {
            this.setState({ addresses: this.state.addresses.filter(a => a.tid != row.tid) });

        });
    }


    convert(tableOccupations) {
        const t = tableOccupations.map(o => {
            const res = {};
            res.date = moment(o.bookedOccupationStart ? o.bookedOccupationStart : o.occupationStart).format("YYYY-MM-DD HH:mm");
            res.d = o.bookedOccupationStart ? o.bookedOccupationStart : o.occupationStart;
            res.amount = o?.tableMatePayments.filter(t => t?.tableMate?.tableMateId == this.state.id).map(p => p.payable).reduce((a, b) => a + b, 0);
            res.type = o?.restaurantTables[0]?.restaurantTable?.name;
            res.status = o.active ? I18n.t("admin_local.active") : res.amount > 0 ? I18n.t("local.paid") : I18n.t("local.cancelled");
            res.comment = o?.bookedProperties?.comment || "";
            return res;
        });
        t.sort((b, a) => a.d - b.d);
        return t;
    }

    onChangeIndex = 0;

    onDelete = async (event) => {
        if (event.target.id === "phone") {
            store.dispatch(EatWithMeActions.phoneremove(event.target.value));
        }
    }

    onChange = async (event, valid, completeNumber = false) => {
        const state = { ..._state, ...this.state };
        state.errorField = undefined;
        state.focus = false;
        delete state.id;
        state[event.target.id] = event.target.value;
        state[event.target.id + "_valid"] = valid;
        state.booking = { ...this.state.booking, changed: true };
        if (event.target.id === "town") {
            localStorage.defaultTown = event.target.value;
        }
        /*
    if (this.onChangeTimeout) clearTimeout(this.onChangeTimeout);
    this.onChangeTimeout = setTimeout(() => {
            }, 500);*/
        this.setState(state);
        if (event.target.id === "search" && state.search.length > 2) {
            if (this.getUsersContaining) clearTimeout(this.getUsersContaining);
            this.getUsersContaining = setTimeout(() => {
                this.getUsersContaining = undefined;
                this.onChangeIndex++;
                const index = this.onChangeIndex;
                getUsersContaining(state.search, data => {
                    if (this.onChangeIndex === index) {
                        this.setState({ customers: data, addresses: null, tableOccupations: null });
                        if (data.length === 1 && completeNumber) {
                            this.customerSelected(data[0]);
                        }
                    }
                });
            }, 1000);
        } else {
            if ((event.target.id === "email" || event.target.id === "name" || event.target.id === "surname" || event.target.id === "phone") && ((state.email.length > 0 ? 1 : 0) + (state.name.length > 0 ? 1 : 0) + (state.surname.length > 0 ? 1 : 0) + (state.phone.length > 0 ? 1 : 0))) {
                if (this.getUsersContaining) clearTimeout(this.getUsersContaining);
                this.getUsersContaining = setTimeout(() => {
                    this.getUsersContaining = undefined;
                    const search = state.email + state.name + state.surname + state.phone;
                    if (search.length > 2) {
                        this.onChangeIndex++;
                        const index = this.onChangeIndex;
                        getUsersContaining(state.email, state.name, state.surname, state.phone, data => {
                            if (this.onChangeIndex === index)
                                this.setState({ customers: data, addresses: null, tableOccupations: null });
                            if (data.length === 1 && completeNumber) {
                                this.customerSelected(data[0]);
                            }
                            if (!data.length) {
                                if (state.phone.startsWith("+36")) {
                                    getUsersContaining(state.email, state.name, state.surname, state.phone.replace("+36", "06"), data => {
                                        if (this.onChangeIndex === index)
                                            this.setState({ customers: data, addresses: null, tableOccupations: null });
                                        if (data.length === 1 && completeNumber) {
                                            this.customerSelected(data[0]);
                                        }
                                    });
                                } else if (state.phone.startsWith("06")) {
                                    getUsersContaining(state.email, state.name, state.surname, state.phone.replace("06", "+36"), data => {
                                        if (this.onChangeIndex === index)
                                            this.setState({ customers: data, addresses: null, tableOccupations: null });
                                        if (data.length === 1 && completeNumber) {
                                            this.customerSelected(data[0]);
                                        }
                                    });
                                }

                            }
                        });
                    }
                }, 1000);
            }
        }
        switch (event.target.id) {
            case "address":
                this.setState({ latitude: undefined, longitude: undefined })
            case "town":
            case "postcode":
                if (this.updateMapTimeout) clearTimeout(this.updateMapTimeout);
                this.updateMapTimeout = setTimeout(() => this.updateMap(state), 300);
                break;
            default:
        }
    }

    address_pattern = new RegExp(removeAccents(I18n.t('admin_local.address_pattern')));
    postcode_pattern = new RegExp(removeAccents(I18n.t('admin_local.postcode_pattern')));

    addressSelected = address => {
        if (address.length > 0 && address[0].x) {
            const state = { center: [address[0].y, address[0].x], longitude: address[0].x, latitude: address[0].y, ...address[0], postcode_valid: true };
            delete state.id;
            this.setState(state);
            this.updateMap(address[0]);
        }
    }

    updateMap = async (state) => {
        this.updateMapTimeout = undefined;
        if (!map)
            return;
        try {

            const filterResult = (results) =>
                results.map(r => {
                    //const m = removeAccents(r.label).match(pattern);
                    const pp = r.label.split(",");
                    //console.log(pp);
                    if (pp.length < 7) {
                        return { town: pp[0], x: r.x, y: r.y };
                    }
                    var address1 = pp[0].trim();
                    var address2 = pp[1].trim();
                    var address3 = pp[2].trim();
                    var postcode = pp[pp.length - 2].trim();
                    var address = null;
                    const match = removeAccents(state.address.toLowerCase()).match(this.address_pattern);
                    const match1 = removeAccents(address1 + " 1").match(this.address_pattern);
                    const match2 = removeAccents(address2 + " 1").match(this.address_pattern);
                    const match3 = removeAccents(address3 + " 1").match(this.address_pattern);
                    const match4 = removeAccents(postcode).match(this.postcode_pattern);
                    var town = pp.find(t => t.trim() === state.town) ? state.town : postcodes[pp[pp.length - 1].trim()] ? postcodes[pp[pp.length - 1].trim()][postcode] : pp.find(p => p == state.town);
                    if (!match4) {
                        return { postcode: postcode, town: town, x: r.x, y: r.y };
                    }
                    if (match1)
                        address = address1;
                    else if (match2)
                        address = address2;
                    else if (match3)
                        address = address3;
                    if (!address)
                        return { postcode: postcode, town: town, x: r.x, y: r.y };
                    if (match && match[4])
                        return { address: address + " " + match[4], postcode: postcode, town: town, x: r.x, y: r.y }
                    else
                        return { address: address, postcode: postcode, town: town, x: r.x, y: r.y }
                }).filter(p => {
                    return p && (!state.postcode || state.postcode == p.postcode) && (!state.town || state.town == p.town)
                });


            if (this.updateMapTimeout)
                clearTimeout(this.updateMapTimeout);
            this.updateMapTimeout = setTimeout(async () => {
                this.updateMapTimeout = null;
                if (!state.postcode && state.country === "Magyarország" && state.town) {
                    const postcode = Object.keys(postcodes.Hungary).filter(p => postcodes.Hungary[p] === state.town);
                    if (postcode.length === 1) {
                        state.postcode = postcode[0];
                    }
                }
                try {
                    var query = { street: state.address.toLowerCase(), postalcode: state.postcode, city: state.town, town: state.town, country: this.props.myStatus.restaurant_country };
                    var results = await provider.search({ query: query });
                } catch (ex) {
                    results = await provider.search({ query: state.address.toLowerCase() + ", " + state.postcode + " " + state.town + ", " + state.postcode + " , " + this.props.myStatus.restaurant_country });
                }
                var places = filterResult(results);

                if (!places.length) {
                    try {
                        var address = state.address.toLowerCase();
                        var match = address.match(/(.*) (\d+)\.?/);
                        if (match) {
                            address = match[1];
                            var query = { street: address, postalcode: state.postcode, city: state.town, town: state.town, country: this.props.myStatus.restaurant_country };
                            results = await provider.search({ query: query });
                            places = filterResult(results);
                        }
                    } catch (ex) {
                        results = await provider.search({ query: address + ", " + state.postcode + " " + state.town + ", " + state.postcode + " , " + this.props.myStatus.restaurant_country });
                    }
                }

                const res = [];
                places.forEach(p => {
                    if (p !== null && res.filter(r => r.address === p.address && r.postcode === p.postcode).length === 0)
                        res.push(p);
                })
                this.setState({
                    address_choices: res.filter(r => r.address)
                });

                if (places.length > 0 && places[0].y) {
                    //this.setState({ longitude: state.longitude || places[0].x, latitude: state.latitude || places[0].y, zoom: 16 })
                    //console.log(state.latitude || places[0].y, state.longitude || places[0].x, state);
                    this.setState({ center: [places[0].y, places[0].x] })
                    //map.map.flyTo([state.latitude || places[0].y, state.longitude || places[0].x], 16);
                } else if (results.length > 0 && results[0].y) {
                    this.setState({ center: [results[0].y, results[0].x] })
                }
            }, 1000);
        } catch (ex) {

        }
    };

    autofill = async (e) => {
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${e.latitude}&lon=${e.longitude}`);
        const data = await response.json();
        console.log(data?.address);
        if (data?.address) {
            this.setState({
                address: (data.address.road + " " + (data.address.house_number || "")).trim(), postcode: data.address.postcode, town: data.address.city || data.address.village
            })
        }
    }
}

const mapStateToProps = (props, state) => {
    return {
        customerSelected: props.rootReducer.ringing.find(n => n.selected)?.details
    };
};

export default connect(mapStateToProps)(CustomerDeliveryDetails);

import React from "react";
import { connect } from "react-redux";
import auth, { ajaxCallEnd, ajaxCallStart, getMyImageUrl, getParameterByName, getString } from "../js/auth";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import "daterangepicker/daterangepicker.css";
import BackButton from "../components/menus/BackButton";
import FlexGrowSpace from "../widgets/FlexGrowSpace";
import RestaurantSelector from "../components/RestaurantSelector";
import AbstractMenuItem from "../components/menus/AbstractMenu";
import { Translate } from "react-redux-i18n";

class Report extends AbstractMenuItem {
    state = {
        initialized: false,
    };

    constructor() {
        super('admin_local.menus.daily_closing');
    }

    load = (props) => {
        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {

            var parameters = "";
            if (getParameterByName('table') != null)
                parameters += "&table=" + getParameterByName('table');
            if (getParameterByName('orderedBy') != null)
                parameters += "&orderedBy=" + getParameterByName('orderedBy');
            if (getParameterByName('menuitem') != null)
                parameters += "&menuitem=" + getParameterByName('menuitem');
            var report = this.props.match.params.report;

            var url = auth.server + "/eatwithme.server/printerServerClient/" + sessionStorage.restaurantSelected + "/generateReport/" + report + "?__format=pdf&__nocache=true&__overwrite=true&authentication=true&restaurant=" + sessionStorage.restaurantSelected + "&lang=" + localStorage.language + "&" + parameters + "&instance=" + localStorage.instance;

            getString(url).done(url => {
                this.setState({ url: url, initialized: true })
            });
        }
    }

    componentDidMount = this.load;

    componentDidUpdate = this.load;

    render() {
        if (this.state.url) {
            ajaxCallStart("load");
            return (
                <div id="report" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
                    <div className="menuItemHeader">
                        <BackButton {...this.props} />
                        <FlexGrowSpace />
                        <RestaurantSelector {...this.props} onReload={() => { this.code.fullreload(this.props, this.state); }} />
                        <FlexGrowSpace />
                        <span className="myTitle" >
                            {<Translate value={"admin_local.menus." + this.props.match.params.report} />}
                        </span>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <ChangeLanguage />
                            <UserImageButton src={getMyImageUrl()} />
                        </div>
                    </div>
                    <div id="report" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
                        <iframe title="result" src={this.state.url} style={{ height: "100%" }} onLoad={ajaxCallEnd} />
                    </div >
                </div >
            );
        } else return null;
    }


}
const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(Report);

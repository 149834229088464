import React, { Component } from "react";
import TopBar from "../TopBar";
import MainMenu from "./MainMenu";
import SuperAdminMenus from "./SuperAdminMenus";
import AdminMenu from "./AdminMenu";
import StockManagementMenu from "./StockManagementMenu";
import ProductionLinesMenu from "./ProductionLinesMenu";
import ReportsMenu from "./ReportsMenu";
import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PointOfSalesMenu from "./PointOfSalesMenu";
import Reservations from "../../main/orders/Reservations";
import CustomerScreen from "../../main/pointOfSale/CustomerScreen";
import moment from "moment";
import { I18n } from "react-redux-i18n";
import OpeningHours from "../../main/openingHours/OpeningHours";
import Drinks from "../../admin/drinks/Drinks";
import Meals from "../../admin/meals/Meals";
import Restaurants from "../../admin/Restaurants";
import Printers from "../../admin/Printers";
import ProductionLines from "../../admin/ProductionLines";
import Tables from "../../admin/Tables";
import TableLayouts from "../../admin/TableLayouts";
import VatCategories from "../../admin/VatCategories";
import QuantityTypes from "../../admin/QuantityTypes";
import DiscountTypes from "../../admin/DiscountTypes";
import Advertisements from "../../admin/Advertisements";
import Labels from "../../admin/Labels";
import CashRegisters from "../../admin/CashRegisters";
import BankTerminals from "../../admin/BankTerminals";
import Scales from "../../admin/Scales";
import Wolt from "../../admin/Wolt";
import FoodPanda from "../../admin/FoodPanda";
import Falatozz from "../../admin/Falatozz";
import Softphone from "../../main/tablesOverview/Softphone";

class Menu extends Component {

  componentDidUpdate = () => {
    const { myStatus } = this.props;
    if (!this.props.myStatus.subscription || myStatus.roles.filter(r => r.role === "superadmin").length)
      return;

    /*
    var dueSince = myStatus.invoiceDueSince;
    if (dueSince > 0) {
      const instance = this;
      var duration = dueSince;
      this.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_was_due_in"), dueSince) + "<br/>" + String.format(I18n.t('admin_local.continue_in'), duration));
      function updateMessage() {
        duration -= 1;
        instance.dialog.resolve();
        if (duration > 0) {
          instance.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_was_due_in"), dueSince) + "<br/>" + String.format(I18n.t('admin_local.continue_in'), duration));
          setTimeout(updateMessage, 60000);
        }
      }
      setTimeout(updateMessage, 60000);
      this.dialog.done(() => {
        delete this.dialog;
      });
    } else if (dueSince >= -3) {
      this.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_due_in"), -dueSince));
      setTimeout(this.dialog.resolve, (5 + dueSince) * 1000);
      this.dialog.done(() => {
        delete this.dialog;
      });
    }
    */
  }

  render() {
    var dueSince = -this.props.myStatus.invoiceDueSince;
    dueSince = Math.min(Math.max(dueSince, -15), 15);
    const dueSincePercent = (dueSince + 15) / 30 * 100;
    var bg;
    var text;
    var style = { position: "relative", width: "fit-content", paddingLeft: "3px", paddingRight: "3px", left: dueSincePercent + "%" };
    var style2 = { position: "absolute", width: "fit-content", top: "0", paddingLeft: "3px", paddingRight: "3px", left: dueSincePercent + "%" };
    const date = moment().add(dueSince, "day");
    if (dueSince >= 0) {
      style.transform = "translateX(-100%)";
      bg = "linear-gradient(90deg, rgba(63,249,18,1) 0%,  rgba(63,249,18,1) 50%, rgba(0,0,0,.5) 50%, rgba(0,0,0,.5) calc(50% + 1px), rgba(63,249,18,1) calc(50% + 1px), rgba(63,249,18,1) calc(50% + 3px), rgba(0,0,0,.5) calc(50% + 3px), rgba(0,0,0,.5) calc(50% + 4px), rgba(63,249,18,1) calc(50% + 4px), " + dueSincePercent + "%, rgba(248,47,21,1) " + dueSincePercent + "%, rgba(248,47,21,1) 100%)";
      text = String.format(I18n.t("admin_local.payment_due_in"), dueSince);
    } else {
      style2.transform = "translateX(-100%)";
      style.color = "drakgray";
      bg = "linear-gradient(90deg, rgba(63,249,18,1) 0%, rgba(22,249,29,1) " + dueSincePercent + "%, rgba(248,47,21,1) " + dueSincePercent + "%, rgba(248,47,21,1) 50%, rgba(255,255,255,.5) 50%, rgba(255,255,255,.5) calc(50% + 1px), rgba(248,47,21,1) calc(50% + 1px), rgba(248,47,21,1) calc(50% + 3px), rgba(255,255,255,.5) calc(50% + 3px), rgba(255,255,255,.5) calc(50% + 4px), rgba(248,47,21,1) calc(50% + 4px), rgba(248,47,21,1) 100%)";
      text = String.format(I18n.t("admin_local.payment_was_due_in"), -dueSince);
    }
    if (this.props.myStatus) {
      return (
        <Switch>
          <Route path="/superAdminMenus" render={props => <SuperAdminMenus {...this.props} />} />
          <Route path="/opening_hours" render={props => <OpeningHours {...props} />} />
          <Route path="/adminMenu" exact={true} render={props => <AdminMenu {...this.props} />} />
          <Route path="/stockManagementMenu" render={props => <StockManagementMenu {...this.props} />} />
          <Route exact={true} path="/productionLinesMenu" render={props => <ProductionLinesMenu {...this.props} />} />
          <Route path="/pointOfSales" render={props => <PointOfSalesMenu {...props} />} />
          <Route path="/customerScreen/:tableNumber" render={props => <CustomerScreen {...props} />} />
          <Route path="/ordersSetStatus" render={props => <Reservations {...props} qrActionsOnly={true} />} />
          <Route path="/orders" render={props => <Reservations {...props} />} />
          <Route path="/reportsMenu" render={props => <ReportsMenu {...this.props} />} />
          <Route path={"/adminMenu/drinks"} render={props => <Drinks {...props} />} />
          <Route path={"/adminMenu/meals"} render={props => <Meals {...props} />} />
          <Route path={"/adminMenu/restaurants"} render={props => <Restaurants {...props} />} />
          <Route path={"/adminMenu/printers"} render={props => <Printers {...props} />} />
          <Route path={"/adminMenu/production-lines"} render={props => <ProductionLines {...props} />} />
          <Route path={"/adminMenu/tables"} render={props => <Tables {...props} />} />
          <Route path={"/adminMenu/table-layouts"} render={props => <TableLayouts {...props} />} />
          <Route path={"/adminMenu/vatCategories"} render={props => <VatCategories {...props} />} />
          <Route path={"/adminMenu/servings"} render={props => <QuantityTypes {...props} />} />
          <Route path={"/adminMenu/discounts"} render={props => <DiscountTypes {...props} />} />
          <Route path={"/adminMenu/advertisements"} render={props => <Advertisements {...props} />} />
          <Route path={"/adminMenu/labels"} render={props => <Labels {...props} />} />
          <Route path={"/adminMenu/cashRegisters"} render={props => <CashRegisters {...props} />} />
          <Route path={"/adminMenu/bankTerminals"} render={props => <BankTerminals {...props} />} />
          <Route path={"/adminMenu/scales"} render={props => <Scales {...props} />} />
          <Route path={"/adminMenu/wolt"} render={props => <Wolt {...props} />} />
          <Route path={"/adminMenu/foodpanda"} render={props => <FoodPanda {...props} />} />
          <Route path={"/adminMenu/falatozz"} render={props => <Falatozz {...props} />} />
          <Route
            path="/"
            render={props => (
              <React.Fragment>
                <TopBar {...this.props} />
                {sessionStorage.restaurantSelected !== "0" ? <MainMenu {...this.props} /> : ""}
                {this.props.myStatus.subscription && dueSince < 8 ?
                  <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                    <div style={{ width: "100%", borderTop: "1px solid lightgray", textAlign: "center", background: bg, position: "relative" }}>
                      <div style={style}>
                        {date.format("YYYY-MM-DD")}
                      </div>
                      <div style={style2}>
                        {text}
                      </div>
                    </div>
                  </div> : <div style={{ flexGrow: 1 }} />}

              </React.Fragment>
            )}
          />
        </Switch>
      );
    } else return null;
  }
}

const mapStateToProps = state => {
  return {
    myStatus: state.rootReducer.myStatus
  };
};

export default connect(mapStateToProps)(withRouter(Menu));
